import React, { useState, useEffect } from 'react'
import { getColor, tooltipFormatter } from '../../helpers/utils'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { LegendComponent, ToolboxComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import Loading from '../common/loading'

echarts.use([ToolboxComponent, LegendComponent, BarChart])

const ChartBar = (props) => {
  const [xaxis, setXaxis] = useState([])
  const [data, setData] = useState([])
  const [loaderStyles, setLoaderStyles] = useState({
    marginTop: '140px',
  })

  useEffect(() => {
    setXaxis(props.xaxis)
    setData(props.data)
  }, [props])

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none',
      },
      position: function (point, params, dom, rect, size) {
        return [point[0], point[1]]
      },
    },
    xAxis: {
      type: 'category',
      data: xaxis,
      inverse: true,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid',
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: (value) => value,
        margin: 15,
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      position: 'right',
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 5,
        formatter: function (value, index) {
          return value > 999999
            ? value / 1000000 + 'M'
            : value > 999
            ? value / 1000 + 'K'
            : value
        },
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300'),
        },
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0,
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: '#2d5fa7',
          borderRadius: [5, 5, 5, 5],
        },
        barWidth: '25px',
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true,
        },
      },
    ],
    grid: { right: '8%', left: '3%', bottom: '8%', top: '8%' },
  })

  return (
    <>
      {props.xaxis !== null && props.data !== null ? (
        <ReactEChartsCore
          echarts={echarts}
          option={getOption()}
          style={{ height: '302px', width: '416px' }}
        />
      ) : (
        <div style={{ height: '302x', width: '416px', position: 'relative' }}>
          <Loading loading={true} styles={loaderStyles} />
        </div>
      )}
    </>
  )
}

export default ChartBar
