import { getValue , deleteValue} from "./storeManager";
function CookieManager(params = {}){


    let _self = this;
    
    this.get = function (name){
        //getcookies
        var cookieArr = document.cookie.split(";");
        for(var i = 0; i < cookieArr.length; i++) {
            var cookie = cookieArr[i].split("=");
            if(name === cookie[0].trim()) {
                return JSON.parse(cookie[1]);
            }
        }
        return null;
    }
        
    this.create = function (name , value){
        
        let cookieData = {
            name : name,
            value : JSON.stringify(value),
            domain : _self.getParentDomain(process.env.REACT_APP_BASE_WHITELIST_URL),
            path : "/",
            max_age : 6000,
         }
         
        //setcookies
            if(cookieData.name && cookieData.value){
                var cookieTxt = cookieData.name + "=" + cookieData.value; 
                cookieTxt+= "; path="+cookieData.path 
                cookieTxt+="; domain="+(cookieData.domain)
                cookieTxt+= "; max-age="+ (cookieData.max_age || 6000)
                cookieTxt+= "; Secure"
                document.cookie = cookieTxt;
                // console.log("cookie set");
                return document.cookie;
            }
            else
            {
                return null;
            }
     }
    
    this.remove = function(name){
        deleteValue("customer_domain");
        document.cookie=name+'=; path=/; domain='+_self.getParentDomain(process.env.REACT_APP_BASE_WHITELIST_URL)+'; max-age=0'
    }

    this.getParentDomain = function(BASE_URL){
        //getParent domain of current subdomain
       if(BASE_URL)
       {
        return  BASE_URL.indexOf("localhost") === -1 ? BASE_URL.split('>')[1] : "localhost";     
       }
       else{
           return null;
       }
   }

   this.getCustomerDomain = function(){
    let customer_domain = getValue("customer_domain");
    return (customer_domain || "app");
   }
 }

export const CookieUtils = new CookieManager();
