import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ChartBar from './ChartBar'

const ChartBarBuild = (props) => {
  const [loginDates, setLoginDates] = useState([])
  const [occurrences, setOccurrences] = useState([])
  let diffDays = 0

  useEffect(() => {
    if (props.loginList !== null) {
      buildChart()
    }
  }, [props])

  const buildChart = () => {
    setLoginDates([])
    setOccurrences([])
    
    let _datesArray = props.loginList.map((item) => moment(new Date(item['detectionTime'])).format('D MMM'))
    setLoginDates(_datesArray) 
    let _occurrences = props.loginList.map((item) => item['records'])
    setOccurrences(_occurrences)
  }

  return (
    <ChartBar
      xaxis={loginDates.length > 0 ? loginDates : null}
      data={occurrences.length > 0 ? occurrences : null}
    />
  )
}

const mapStateToProps = (state) => ({
  filters: state.entities.events.filters,
  loginTotalSize: state.entities.events.loginTotalSize,
})

export default connect(mapStateToProps)(ChartBarBuild)
