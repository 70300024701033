export const validateAlphabets = (input) => {
  let pattern = new RegExp(/^[A-Za-z]$/)
  return pattern.test(input) ? true : false
}

export const validateAlphaNumerics = (input) => {
  let pattern = new RegExp(/[A-Za-z0-9]$/)
  return pattern.test(input) ? true : false
}

export const validateEmail = (input) => {
  let pattern = new RegExp(
    /^[A-z0-9_-]+(?:\.[A-z0-9_-]+)*@[A-z0-9-]+(?:\.[A-z0-9-]+)*\.[A-z]{2,6}$/,
  )
  return pattern.test(input) ? true : false
}

export const validatePhoneNumber = (input) => {
  // /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/
  let pattern = new RegExp(/^[0-9]{10,10}$/)
  return pattern.test(input) ? true : false
}
