import React from 'react';
import Style from './SocIntegration.module.css';

const SocDetails =(props) => {

    const copyToClipboard = () => {
        let textToCopy = props.apiData.curlReq
        navigator.clipboard.writeText(textToCopy);
    }

    return (
        <>
            <div className={``}>
                <div className={`d-flex flex-sm-column flex-xs-column flex-md-row flex-lg-row justify-content-between`}>
                    <div className={`${Style.subInfoContainerStyle} ${Style.textStyle} p-4 w-sm-100 w-xs-100 w-md-50 w-lg-50 mr-3`}>
                        <div className={`${Style.subTitleTextStyle}`}>
                            Description
                        </div>
                        <span dangerouslySetInnerHTML={{ __html: props.apiData.tabDesc }}></span>
                    </div>
                    <div className={`${Style.subInfoContainerStyle} ${Style.textStyle} p-4 w-sm-100 w-xs-100 w-md-50 w-lg-50`}>
                        Example cURL to fetch incidents in Terminal. Click below text to copy cURL to clipboard.
                        <div className={`${Style.greyBoxTextStyle} p-3 mt-2`} onClick={() => copyToClipboard()} title='click to copy cURL to clipboard'>
                            <pre  className={`${Style.greyBoxTextStyle}`} onClick={() => copyToClipboard()} title='click to copy cURL to clipboard'>
                                {props.apiData.curlReq }
                            </pre>
                        </div>
                    </div>
                </div>
            </div>

        <div className={`mt-4 pb-5`}>
            <div className={`d-flex justify-content-between mt-4`}>
                <div className={`${Style.subInfoContainerStyle} p-4`}>
                    <div className={`${Style.subTitleTextStyle} `}>
                        Request format
                    </div>
                 
                    <div className={`${Style.formatSection} rounded-lg d-flex flex-row mb-0`} style={{backgroundColor:'white'}}>
                        <div className={`${Style.formatSectionParameter} ${Style.textStyle} text-dark d-flex my-auto`}>
                            <b>Required Header Parameter</b>
                        </div>
                        <div className={`d-flex align-items-center justify-content-start ${Style.formatSectionDescription} ${Style.textStyle} ${Style.textStyle} `}
                        >
                        <b>Description</b>
                        </div>
                        <div className={`${Style.formatSectionUse} ${Style.textStyle}  d-flex align-items-center justify-content-start`}
                        >
                        <b>How to Use</b>
                        </div>
                    </div>

                {props.apiData.headerParams.map((headerParam, index)=>
                    <div key={headerParam.id} className={`${Style.formatSection} rounded-lg d-flex flex-row`}>
                        <div className={`${Style.formatSectionParameter} ${Style.textStyle} text-dark d-flex my-auto`}>
                        {headerParam.displayName}
                        </div>
                        <div className={`d-flex align-items-center justify-content-start ${Style.formatSectionDescription} ${Style.textStyle} ${Style.textStyle} ${Style.verticalLine}`}
                        >
                            <span dangerouslySetInnerHTML={{ __html: headerParam.desc }}></span>
                        </div>
                        <div className={`${Style.formatSectionUse} ${Style.verticalLine} d-flex justify-content-start`}
                        >
                        <pre dangerouslySetInnerHTML={{ __html: headerParam.example }} style={{fontSize:'12px'}}>
                        </pre>
                        </div>
                    </div>
                )
                }

                {props.apiData.queryParams.length > 0 ?
                <div>
                    <div className={`${Style.formatSection} rounded-lg d-flex flex-row mb-0`} style={{backgroundColor:'white'}}>
                        <div className={`${Style.formatSectionParameter} ${Style.textStyle} text-dark d-flex my-auto`}>
                            <b>Optional Query Parameters</b>
                        </div>
                        <div className={`d-flex align-items-center justify-content-start ${Style.formatSectionDescription} ${Style.textStyle} ${Style.textStyle} `}
                        >
                        <b>Description</b>
                        </div>
                        <div className={`${Style.formatSectionUse} ${Style.textStyle}  d-flex align-items-center justify-content-start`}
                        >
                        <b>How to Use</b>
                        </div>
                    </div>

                {props.apiData.queryParams.map((queryParam, index)=>
                    <div key={queryParam.id} className={`${Style.formatSection} rounded-lg d-flex flex-row`}>
                        <div className={`${Style.formatSectionParameter} ${Style.textStyle} text-dark d-flex my-auto`}>
                        {queryParam.displayName}
                        </div>
                        <div  className={`d-flex align-items-center justify-content-start ${Style.formatSectionDescription} ${Style.textStyle} ${Style.textStyle} ${Style.verticalLine}`}
                        >
                            <span dangerouslySetInnerHTML={{ __html: queryParam.desc }}></span>
                        </div>
                        <div className={`${Style.formatSectionUse} ${Style.verticalLine} d-flex justify-content-start`}
                        >
                        <pre dangerouslySetInnerHTML={{ __html: queryParam.example }} style={{fontSize:'12px'}}>
                        </pre>
                        </div>
                    </div>
                )
                }
                </div>
                : null}

                </div>
                <div className={`${Style.subInfoContainerStyle} p-4`}>
                    <div className={`${Style.subTitleTextStyle}`}>
                        Response format
                    </div>
                    <div className={`${Style.greyBoxTextStyle} pt-3 px-3 pb-1`}>
                        <pre className={`${Style.greyBoxTextStyle}`}>
                            {
                                JSON.stringify(props.apiData.resposeFormat, null, 4)
                            }
                        </pre>
                    </div>
                    <div className={`${Style.subTitleTextStyle} mt-5`}>
                        HTTP Response Status Codes
                    </div>
                    <div>
                        <div className={`${Style.formatSection} rounded-lg d-flex flex-row mb-0`} style={{backgroundColor:'white'}}>
                        <div className={`${Style.formatSectionParameter} ${Style.textStyle} text-dark d-flex my-auto`}>
                            <b>Status code</b>
                        </div>
                        <div className={`d-flex align-items-center justify-content-start ${Style.formatSectionDescription} ${Style.textStyle} ${Style.textStyle} `}
                        >
                        <b>Description</b>
                        </div>
                        
                    </div>
                {props.apiData.responseStatusCodes.map((responseStatusCode, index)=>
                    <div key={responseStatusCode.code} className={`${Style.formatSection} rounded-lg d-flex flex-row`}>
                        <div className={`${Style.formatSectionParameter} ${Style.textStyle} text-dark d-flex my-auto`}>
                        {responseStatusCode.code}
                        </div>
                        <div className={`d-flex align-items-center justify-content-start ${Style.formatSectionDescription} ${Style.textStyle} ${Style.textStyle} ${Style.verticalLine} w-75`}
                        >
                            <span dangerouslySetInnerHTML={{ __html: responseStatusCode.desc }}></span>
                        </div>
                    </div>
                )}

                    </div>
                    <div className={`${Style.subTitleTextStyle} mt-5`}>
                        Response Type
                    </div>
                    <div>
                        <div className={`${Style.formatSection} rounded-lg d-flex flex-row mb-0`} style={{backgroundColor:'white'}}>
                        <div className={`${Style.formatSectionParameter} ${Style.textStyle} text-dark d-flex my-auto`}>
                            <b>Parameter</b>
                        </div>
                        <div className={`d-flex align-items-center justify-content-start ${Style.formatSectionDescription} ${Style.textStyle} ${Style.textStyle} `}
                        >
                        <b>Value</b>
                        </div>
                        
                    </div>
                    {props.apiData.responseTypes.map((responseType, index)=>
                        <div key={responseType.id} className={`${Style.formatSection} rounded-lg d-flex flex-row`}>
                            <div className={`${Style.formatSectionParameter} ${Style.textStyle} text-dark d-flex my-auto`}>
                            {responseType.param}
                            </div>
                            <div className={`d-flex align-items-center justify-content-start ${Style.formatSectionDescription} ${Style.textStyle} ${Style.textStyle} ${Style.verticalLine} w-75`}
                            >
                                <span dangerouslySetInnerHTML={{ __html: responseType.value }}></span>
                            </div>
                        </div>
                    )}

                    </div>
                </div>
            </div>
        </div>
    </>
    )
}



export default SocDetails