import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import '../Themes/Common/Common.scss'
import Chart from 'chart.js/auto'
import { isGlobalView } from '../utilities/config'
import { connect } from 'react-redux'
import {
  getNewUsers,
  getRegisteredUsers,
  getBreakDowns,
  getDetectedUrlBreakdowns,
  getVerifiedUrlBreakdowns,
  getUrlUsersBreakdowns,
  getExtensionUsersCount,
} from '../store/entities/customers'
import DataWithRoundChart from './charts/DataWithRoundChart'
import DoughnutChart from './charts/DoughnutChart'
import PieChart from './charts/PieChart'
import InfoCharts from './charts/InfoCharts'
import StackBarChart from './charts/StackBarChart'

const Dashboard = (props) => {
  useEffect(() => {
    const { selectedCustomer } = props
    if (selectedCustomer) {
      bindTotalUserRegistration(selectedCustomer)
      bindNewUsers(selectedCustomer)
      bindActiveLicenseUsers(selectedCustomer)
      bindLicenseUsers(selectedCustomer)
      bindDetectedUrlBreakdowns(selectedCustomer)
      bindVerifiedUrlBreakdowns(selectedCustomer)
      bindUrlUsers(selectedCustomer)
    }
  }, [props.selectedCustomer])

  const bindTotalUserRegistration = (selectedCustomer) => {
    props.getRegisteredUsers(
      { offset: 0, pageSize: 1 },
      selectedCustomer.id,
      isGlobalView(selectedCustomer.organizationName),
    )
  }

  const bindNewUsers = (selectedCustomer) => {
    props.getNewUsers(
      {},
      selectedCustomer.id,
      isGlobalView(selectedCustomer.organizationName),
    )
  }

  const bindActiveLicenseUsers = (selectedCustomer) => {
    props.getExtensionUsersCount(
      selectedCustomer.id,
      isGlobalView(selectedCustomer.organizationName),
    )
  }

  const bindLicenseUsers = (selectedCustomer) => {
    props.getBreakDowns(
      selectedCustomer.id,
      isGlobalView(selectedCustomer.organizationName),
    )
  }

  const bindDetectedUrlBreakdowns = (selectedCustomer) => {
    props.getDetectedUrlBreakdowns(
      { daysduration: 7 },
      selectedCustomer.id,
      isGlobalView(selectedCustomer.organizationName),
    )
  }

  const bindVerifiedUrlBreakdowns = (selectedCustomer) => {
    props.getVerifiedUrlBreakdowns(
      { daysduration: 30 },
      selectedCustomer.id,
      isGlobalView(selectedCustomer.organizationName),
    )
  }

  const bindUrlUsers = (selectedCustomer) => {
    props.getUrlUsersBreakdowns(
      { daysduration: 30, topvisitors: 10 },
      selectedCustomer.id,
      isGlobalView(selectedCustomer.organizationName),
    )
  }

  return (
    <div className='pageContainer mt-3'>
      <Row>
        <Col md={4} sm={12} className={'mb-3'}>
          <InfoCharts
            data={
              props.registerUserValue ? props.registerUserValue.count : null
            }
            heading="Total User Registration"
            onRefresh={() => bindTotalUserRegistration(props.selectedCustomer)}
            loading={props.registeredUsersLoading}
            errorNote={props.registeredUsersErrorMsg}
          />
        </Col>

        <Col md={4} sm={12} className={'mb-3'}>
          <InfoCharts
            data={
              props.newCustomersValues ? props.newCustomersValues.count : null
            }
            heading="New users in last 7 days"
            onRefresh={() => bindNewUsers(props.selectedCustomer)}
            loading={props.newUsersLoading}
            errorNote={props.newUsersErrorMsg}
          />
        </Col>

        <Col md={4} sm={12} className={'mb-3'}>
          <InfoCharts
            data={props ? props.userCount : null}
            heading="Active Users"
            onRefresh={() => bindActiveLicenseUsers(props.selectedCustomer)}
            loading={props.extensionUsersLoading}
            errorNote={props.extensionUsersErrorMsg}
          />
        </Col>

        <Col md={6} sm={12} className={'mb-3'}>
          <DataWithRoundChart
            data={props ? props.browser : null}
            heading="Browser Type"
            onRefresh={() => bindLicenseUsers(props.selectedCustomer)}
            loading={props.breakdownsLoading}
            errorNote={props.breakDownsErrorMsg}
          />
        </Col>

        <Col md={6} sm={12} className={'mb-3'}>
          <DataWithRoundChart
            data={props ? props.os : null}
            heading="OS Type"
            onRefresh={() => bindLicenseUsers(props.selectedCustomer)}
            loading={props.breakdownsLoading}
            errorNote={props.breakDownsErrorMsg}
          />
        </Col>

        <Col md={12} sm={12} className={'mb-3'}>
          <StackBarChart
            xaxis={props ? props.detectedUrlXaxis : null}
            legend={props ? props.detectedUrlLegend : null}
            values={props ? props.detectedUrlValues : null}
            heading="Events"
            onRefresh={() => bindDetectedUrlBreakdowns(props.selectedCustomer)}
            loading={props.detectedUrlBreakdownsLoading}
            errorNote={props.detectedUrlBreakdownsErrorMsg}
          />
        </Col>

        <Col md={6} sm={12} className={'mb-3'}>
          <DoughnutChart
            data={props ? props.detectedUrlTotal : null}
            heading="Events Total"
            onRefresh={() => bindDetectedUrlBreakdowns(props.selectedCustomer)}
            loading={props.detectedUrlBreakdownsLoading}
            errorNote={props.detectedUrlBreakdownsErrorMsg}
          />
        </Col>

        <Col md={6} sm={12} className={'mb-3'}>
          <DoughnutChart
            data={props ? props.device : null}
            heading="Device"
            onRefresh={() => bindLicenseUsers(props.selectedCustomer)}
            loading={props.breakdownsLoading}
            errorNote={props.breakDownsErrorMsg}
          />
        </Col>

        <Col md={6} sm={12} className={'mb-3'}>
          <DoughnutChart
            data={
              props
                ? {
                    legend: props.verifiedEventsLegends,
                    values: props.verifiedEventsValues,
                  }
                : null
            }
            heading="Verified vs Unverified"
            onRefresh={() => bindVerifiedUrlBreakdowns(props.selectedCustomer)}
            loading={props.verifiedUrlBreakdownsLoading}
            errorNote={props.verifiedUrlBreakdownsErrorMsg}
          />
        </Col>

        <Col md={6} sm={12} className={'mb-3'}>
          <PieChart
            data={
              props
                ? {
                    legend: props.urlUsersLegends,
                    values: props.urlUsersValues,
                  }
                : null
            }
            heading="Most common threats per unique users"
            onRefresh={() => bindUrlUsers(props.selectedCustomer)}
            loading={props.urlUserBreakdownsLoading}
            errorNote={props.urlUserBreakdownsErrorMsg}
          />
        </Col>
      </Row>
    </div>
  )
}

const mapStateToProps = (state) => ({
  registerUserValue: state.entities.customers.registerUserValue,
  newCustomersValues: state.entities.customers.newCustomersValues,
  userCount: state.entities.customers.usersCount,
  browser: state.entities.customers.browser,
  os: state.entities.customers.os,
  device: state.entities.customers.device,
  detectedUrlXaxis: state.entities.customers.detectedUrlXaxis,
  detectedUrlLegend: state.entities.customers.detectedUrlLegend,
  detectedUrlValues: state.entities.customers.detectedUrlValues,
  detectedUrlTotal: state.entities.customers.detectedUrlTotal,
  verifiedEventsLegends: state.entities.customers.verifiedEventsLegends,
  verifiedEventsValues: state.entities.customers.verifiedEventsValues,
  urlUsersLegends: state.entities.customers.urlUsersLegends,
  urlUsersValues: state.entities.customers.urlUsersValues,
  selectedCustomer: state.entities.customers.selected,
  registeredUsersLoading: state.entities.customers.registeredUsersLoading,
  newUsersLoading: state.entities.customers.newUsersLoading,
  extensionUsersLoading: state.entities.customers.extensionUsersLoading,
  breakdownsLoading: state.entities.customers.breakdownsLoading,
  detectedUrlBreakdownsLoading:
    state.entities.customers.detectedUrlBreakdownsLoading,
  verifiedUrlBreakdownsLoading:
    state.entities.customers.verifiedUrlBreakdownsLoading,
  urlUserBreakdownsLoading: state.entities.customers.urlUserBreakdownsLoading,
  registeredUsersErrorMsg: state.entities.customers.registeredUsersErrorMsg,
  newUsersErrorMsg: state.entities.customers.newUsersErrorMsg,
  extensionUsersErrorMsg: state.entities.customers.extensionUsersErrorMsg,
  breakDownsErrorMsg: state.entities.customers.breakDownsErrorMsg,
  detectedUrlBreakdownsErrorMsg:
    state.entities.customers.detectedUrlBreakdownsErrorMsg,
  verifiedUrlBreakdownsErrorMsg:
    state.entities.customers.verifiedUrlBreakdownsErrorMsg,
  urlUserBreakdownsErrorMsg: state.entities.customers.urlUserBreakdownsErrorMsg,
})

const mapDispatchToProps = (dispatch) => ({
  getRegisteredUsers: (filters, customerId, isGlobalView) =>
    dispatch(getRegisteredUsers(filters, customerId, isGlobalView)),
  getBreakDowns: (customerId, isGlobalView) =>
    dispatch(getBreakDowns(customerId, isGlobalView)),
  getExtensionUsersCount: (selectedId, isGlobalView) =>
    dispatch(getExtensionUsersCount(selectedId, isGlobalView)),
  getDetectedUrlBreakdowns: (customerId, isGlobalView) =>
    dispatch(getDetectedUrlBreakdowns(customerId, isGlobalView)),
  getNewUsers: (filters, customerId, isGlobalView) =>
    dispatch(getNewUsers(filters, customerId, isGlobalView)),
  getVerifiedUrlBreakdowns: (customerId, isGlobalView) =>
    dispatch(getVerifiedUrlBreakdowns(customerId, isGlobalView)),
  getUrlUsersBreakdowns: (customerId, isGlobalView) =>
    dispatch(getUrlUsersBreakdowns(customerId, isGlobalView)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
