/*
Intercepts every request and response 
Adds token to every request
Refreshes token if the accesstoken is expired
*/

import axios from 'axios';
import { CookieUtils } from './utilities/cookies';

export const axiosInstance = axios.create();

function redirectToLoginPage() {
  let BASE_URL = process.env.REACT_APP_BASE_WHITELIST_URL.replace("<DOMAIN>", CookieUtils.getCustomerDomain());
  window.location.replace(`${BASE_URL}/product/login.html?u=${process.env.REACT_APP_API_BASE_URL}&b=${process.env.REACT_APP_BASE_WHITELIST_URL}&s=${process.env.REACT_APP_REGISTER_NEW_URL}`);
}
axiosInstance.interceptors.request.use((config)=>{
    config.headers["Authorization"] = CookieUtils.get("token");
    return config;
  },
  (error) => {
    redirectToLoginPage();
    Promise.reject(error)
  }

)

axiosInstance.interceptors.response.use((response)=> {return response}, 

function (error){
    const originalRequest = error.config
    console.log("Interceptor : ", originalRequest.url, error.response);
    if(typeof error.response !== 'undefined' && error.response.status === 403 && originalRequest.url === `${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token`) {
        //redirect login
        redirectToLoginPage();
    } else if (typeof error.response !== 'undefined' && error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true
        return axiosInstance.post(`${process.env.REACT_APP_API_BASE_URL}/auth/refresh-token`, {
            refresh_token: CookieUtils.get("refresh_token")
        }).then(res => {
            if (res.status === 201 || res.status === 200) {
              CookieUtils.create("token" , res.data.id_token);
              CookieUtils.create("refresh_token", res.data.refresh_token)

              axios.defaults.headers.common['Authorization'] = CookieUtils.get("token");
              return axiosInstance(originalRequest)
            }
        })
    }

    return Promise.reject(error);
})



