import React, { PureComponent } from 'react'
import ChartNestedDoughnut from './charts/ChartNestedDoughnut'

export class LMBrowserChartWrapper extends PureComponent {
  render() {
    return (
      <ChartNestedDoughnut
        data={this.props ? [this.props.os, this.props.browser] : null}
        loading={this.props.breakdownsLoading}
        errorNote={this.props.breakDownsErrorMsg}
      />
    )
  }
}

export default LMBrowserChartWrapper
