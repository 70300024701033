import React, { useEffect, useState } from 'react';
import { Button, Card, Form, FormControl, InputGroup } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addCustomer, setError, unSetStatusCode } from '../store/entities/customers';
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';
import { validateEmail, validateAlphaNumerics } from '../utilities/vaildations' 
import Style from './AdvancedSettings.module.css';
import ConfirmPopup from './common/popover/confirmPopup'


const CreateCustomer = (props) => {
  const [email, setEmail] = useState('')
  const [organizationName, setOrganizationName] = useState('')
  const [subDomain, setSubDomain] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [noOfUsers, setNoOfUsers] = useState('');
  const [loading, setLoading] = useState(false)
  const [isMSP, setIsMSP] = useState(false);

  useEffect(() => {
    if (props.statusCode) {
      setLoading(false)
    }
  }, [props.statusCode])

  const handleClick = (e) => {
    e.preventDefault()

    if(organizationName.length === 0) {
      props.setError('Organisation Name Cannot Be Empty.')
      return
    }

    if(subDomain.length === 0) {
      props.setError('Subdomain Cannot Be Empty.')
      return
    }

    const isEmailValid = validateEmail(email);
    if (!isEmailValid) {
      props.setError('Enter Valid Email.')
      return
    }

    if(firstName.length > 0 && !validateAlphaNumerics(firstName) || firstName.length > 50) {
      props.setError('First name should only be alphanumeric and not more than 50 characters')
      return
    }

    if(lastName.length > 0 && !validateAlphaNumerics(lastName) || lastName.length > 50) {
      props.setError('Last name should only be alphanumeric and not more than 50 characters')
      return
    }

    if(!isMSP){
      if(noOfUsers===""){
        props.setError('No. of users cannot be empty')
        return
      }
      if(noOfUsers < 1 || isNaN(noOfUsers) ){
        props.setError('No. of users is Invalid')
        return
      }
      else if(noOfUsers > 5000000){
        props.setError('No. of users cannot be more than 5M')
        return
      }
    }

    // const isPhoneValid = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(phone)
    if (phone && !/^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(phone)) {
      props.setError('Enter Valid Phone Number.')
      return
    }

    let customerData = {
      email: email,
      organizationName: organizationName,
      subDomain: subDomain,
      firstName: firstName,
      lastName: lastName,
      phone: phone,
      isMSP: isMSP
    }

    if(!isMSP){
      customerData.noOfUsers = noOfUsers
    }
    props.addCustomer(customerData)
    setLoading(true)
    // clearData()
  }

  const clearData = () => {
    setEmail('')
    setOrganizationName('')
    setSubDomain('')
    setFirstName('')
    setLastName('')
    setPhone('')
    setNoOfUsers('')
    setIsMSP(false)
  }

  const handleClose = () => {
    if(props.statusCode === 200)
      clearData();
    props.unSetStatusCode();
  }

  return (
    <div className={`mt-3`}>
      <>
        <div className={`${Style.profileFontStyle}`}>
                Create Customer
            </div>
        <Card className="" style={{width: "641px", minHeight: "500px"}}>
        {
      loading ?
      <LoaderWrapper loading={loading} waitForData={false} isFlex={true} styles={{top: '100px'}}></LoaderWrapper>
      :
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label style={{ fontSize: '20px' }}>Account Details</Form.Label>
                  <Form.Control
                    value={organizationName}
                    name="organizationName"
                    type="text"
                    placeholder="Organisation Name*"
                    onChange={(e) => setOrganizationName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <InputGroup className="mb-3" name="subDomain">
                    <FormControl
                      placeholder="Subdomain*"
                      aria-label="Subdomain"
                      aria-describedby="basic-addon2"
                      value={subDomain}
                      onChange={(e) => setSubDomain(e.target.value)}
                    />
                    <InputGroup.Text id="basic-addon2">
                      @pixm.net
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>

                <Form.Group>
                <Form.Check
                    value={isMSP}
                    name="isMSP"
                    type="checkbox"
                    label="MS(S)P Multi-Tenant Support Required"
                    onChange={(e) => setIsMSP(e.target.checked)}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label style={{ fontSize: '20px' }}>Administartor Details</Form.Label>
                  <Form.Control
                    value={email}
                    name="email"
                    type="text"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="d-flex mb-3"
                  controlId="formBasicPassword"
                >
                  <Form.Control
                    value={firstName}
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    className="mr-1"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                  <Form.Control
                    value={lastName}
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    className="ml-1"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    value={phone}
                    name="phone"
                    type="text"
                    placeholder="Phone Number"
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Form.Group>
                
                {!isMSP && <Form.Group>
                <Form.Control
                    value={noOfUsers}
                    name="noOfUsers"
                    type="number"
                    placeholder="No. of Users*"
                    onChange={(e) => setNoOfUsers(e.target.value)}
                  />
                </Form.Group>}
                <Button variant="info" type="submit" onClick={handleClick}>
                  Create Customer
                </Button>
              </Form>
            </Card.Body>
}
          </Card>
          
        </>  
      {props.statusCode && (
        <ConfirmPopup
        details={{
          icon: '',
          type: props.statusCode === 200 ? 'success':'error',
          title:props.statusCode === 200 ? 'Customer Created Successfully':'Customer Could not be created.',
          description: props.userMsg,
          buttons: 'O',
        }}
        onCloseClick={handleClose} />
      )}
    </div>

  )
}

const mapStateToProps = (state) => ({
  loading: state.entities.customers.loading,
  creds: state.entities.customers.creds,
  userMsg: state.entities.customers.userMsg,
  statusCode: state.entities.customers.statusCode,
})

const mapDispatchToProps = (dispatch) => ({
  addCustomer: (body) => dispatch(addCustomer(body)),
  setError: (error) => dispatch(setError(error)),
  unSetStatusCode: () => dispatch(unSetStatusCode())
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomer)
