import * as actions from '../filters';
const filters = ({ getState, dispatch }) => (next) => async (action) => {
    if (action.type !== actions.filtersOnUpdate.type) return next(action);
    const state = getState();
    let { filters, onSuccess } = action.payload;
    dispatch({
        type: onSuccess,
        payload: {"filters" : filters},
    })
}
export default filters;