import React, { useState, useEffect } from 'react'
import { Card, ProgressBar } from 'react-bootstrap'
import CountUp from 'react-countup'
import { FiRefreshCcw } from 'react-icons/fi'

const InfoCharts = (props) => {
  const [showLoader, setShowLoader] = useState(true)
  const [data, setData] = useState()

  useEffect(() => {
    if (props.data || props.data !== null) {
      setShowLoader(props.loading)
      setData(props.data)
    }
  }, [props])

  return (
    <Card>
      <Card.Body>
        <div className="d-flex">
          <h6 className="flex-1">{props.heading}</h6>
          <FiRefreshCcw
            style={{ cursor: 'pointer' }}
            onClick={props.onRefresh}
          />
        </div>
        {props.errorNote && props.errorNote.length > 0 ? (
          <p>{props.errorNote}</p>
        ) : (
          <div className="display-4 fs-4 mb-2 fw-normal font-sans-serif">
            {showLoader ? (
              <ProgressBar animated={true} now={100} />
            ) : (
              <CountUp
                start={0}
                end={data}
                duration={1}
                suffix=""
                prefix=""
                separator=","
                decimals={0}
                decimal="."
              />
            )}
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default InfoCharts
