import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { getCustomerList, setError } from './store/entities/customers'
import { setNewToken } from './store/entities/user'
import { globalViewText } from './utilities/config'

import {axiosInstance} from './axiosInterceptor'
import jwt_decode from "jwt-decode";
import UserLicenseDetails from './components/UserLicenseDetails'
import TenantManagement from './components/TenantManagement'
import { loginIfExisting } from './store/entities/user'
import { userIsAuthenticated, userIsNotAuthenticated } from './auth'
import Navbar from './components/navbar'
import Login from './components/Login'
import Incidents from './components/incidents'
import IncidentDetails from './components/incidentDetails'
import LicenseManagement from './components/licenseManagement'
import Deployment from './components/deployment'
import Settings from './components/settings'
import AddOrganization from './components/AddOrganization'
import UpdateOrganization from './components/UpdateOrganization'
import AddMspAdmin from './components/AddMspAdmin'
import AddWhitelistDomain from './components/AddWhitelistDomain'
import AddBlacklistUrl from './components/AddBlacklistUrl'
import ModalPopup from './components/common/ModalPopup'
import SocIntegration from './components/SocIntegration'
import Dashboard from './components/Dashboard'
import SocDetails from './components/SocDetails'

import { CookieUtils } from './utilities/cookies'
//import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { Container } from 'react-bootstrap'
import { decodeToken } from './utilities/decodeToken'
import LoaderWrapper from './components/common/LoaderWrapper/LoaderWrapper';
import AdvancedSettings from './components/AdvancedSettings'

const ProtectedIncidents = userIsAuthenticated(Incidents)
const ProtectedIncidentDetails = userIsAuthenticated(IncidentDetails)
const ProtectedLicenseManagement = userIsAuthenticated(LicenseManagement)
const ProtectedDeployment = userIsAuthenticated(Deployment)
const ProtectedSettings = userIsAuthenticated(Settings)
const ProtectedUserLicenseDetails = userIsAuthenticated(UserLicenseDetails)
const RedirectedLogin = userIsNotAuthenticated(Login)
const ProtectedTenantManagement = userIsAuthenticated(TenantManagement)
const ProtectedAddOrganization = userIsAuthenticated(AddOrganization)
const ProtectedUpdateOrganization = userIsAuthenticated(UpdateOrganization)
const ProtectedAddMspAdmin = userIsAuthenticated(AddMspAdmin)
const ProtectedAddWhitelistDomain = userIsAuthenticated(AddWhitelistDomain)
const ProtectedAddBlacklistUrl = userIsAuthenticated(AddBlacklistUrl)
const ProtectedSocIntegration = userIsAuthenticated(SocIntegration)
const ProtectedAdvancedSettings = userIsAuthenticated(AdvancedSettings)

class App extends Component {

  state = {
    custId: null,
    userRole: null,
    customersRendered: false,
    isGlobalView: false
  }

  // init() {
  //   // let currentToken = CookieUtils.get("token"); 
  //   // if(currentToken) {
  //   //   let decodedToken = decodeToken(currentToken);
  //   //   localStorage.setItem("customer_domain" , decodedToken["domain"])
  //   // } else {
  //   //   let domain = window.location.hostname.split(".")[0];
  //   //   let BASE_URL = process.env.REACT_APP_BASE_WHITELIST_URL.replace("<DOMAIN>", domain );
  //   //   window.location.replace(`${BASE_URL}/product/login.html?u=${process.env.REACT_APP_API_BASE_URL}&b=${process.env.REACT_APP_BASE_WHITELIST_URL}&s=${process.env.REACT_APP_REGISTER_NEW_URL}`);
  //   // }
  //   // this.props.loginIfExisting()
  // }

  componentDidMount() {
    // window.onload = () => {
    //   this.init();
    // }
    try {
      let currentToken = CookieUtils.get("token"); 
      if(currentToken) {
        let decodedToken = decodeToken(currentToken);
        localStorage.setItem("customer_domain" , decodedToken["domain"])
      } else {
        let domain = window.location.hostname.split(".")[0];
        let BASE_URL = process.env.REACT_APP_BASE_WHITELIST_URL.replace("<DOMAIN>", domain );
        window.location.replace(`${BASE_URL}/product/login.html?u=${process.env.REACT_APP_API_BASE_URL}&b=${process.env.REACT_APP_BASE_WHITELIST_URL}&s=${process.env.REACT_APP_REGISTER_NEW_URL}`);
      }
      this.props.loginIfExisting()
      if (CookieUtils.get("token")) {
        // this.fetchCustomers();
        this.props.loadCustomers();
      }
    }catch(error){
      console.error(error);
    }

  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {

      let newState = {}
      if (this.props.userRole) {
        // this.setState({
        //   userRole: this.props.userRole
        // })
        newState["userRole"] = this.props.userRole
      }
      if (this.props.selectedCustomer) {
        // this.setState({
        //   custId: this.props.selectedCustomer.id
        // })
        newState["custId"] = this.props.selectedCustomer.id
      }
      if (this.props.selectedCustomer && this.props.selectedCustomer.organizationName === globalViewText) {
        newState["isGlobalView"] = true
      }
      this.setState(newState);
    }
  }

  // fetchCustomers = async () => {
   
  // //  try{
  // //   const customers = await axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/portal/customers`);
  // //   this.props.loadCustomers(customers.data , true , this.props.userRole);
  // //  } 
  // //  catch(error){
  // //     console.log(error);
  // //     this.props.setError("could not load customer details!!");
  // //     this.props.loadCustomers({} , true , this.props.userRole);
  // //  }
    
    
  // }

  renderBodyCompoent = (Body, props, applyBodyStyle) => (
    <Body {...props} />
  )

  render() {
    if (!this.state.userRole) {
      return null
    }

    if (this.state.userRole !== 'TenantAdmin' && !this.props.selectedCustomer) {
      return null
    }


    return (
      <>
        <div className="d-flex flex-row" style={{backgroundColor:'#f7f7f7'}}>
          <ToastContainer />
          <div className="d-flex flex-column ml-auto w-100 backgroundStyle">
            <BrowserRouter>
              <Navbar />
              {/* <LoaderWrapper loading={this.props.loading} waitForData={false}> */}
              <Switch>

                <Route
                  exact
                  path="/"
                  render={() => {
                    return (
                      this.state.userRole == "TenantAdmin" ?
                        <Redirect to="/tenant-admin" /> :
                        <Redirect to={this.state.isGlobalView ? "/tenants/" : "/customers/" + this.props.selectedCustomer.id + "/incidents"} />
                    )
                  }}
                />

                <Route
                  exact
                  path= "/tenants/:param1/:param2/incidents/:param3"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedIncidentDetails, props, true)
                  }
                />
                <Route
                  exact
                  path= "/customers/:param1/incidents/:param3"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedIncidentDetails, props, true)
                  }
                />
                <Route
                  path="/customers/:id/incidents"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedIncidents, props, true)
                  }
                />
                <Route
                  path="/tenants/:id/incidents"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedIncidents, props, true)
                  }
                />

                <Route
                  exact
                  path="/tenant-admin/update-organization"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedUpdateOrganization, props, false)
                  }
                />

                <Route
                  exact
                  path="/tenant-admin/add-admin"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedAddMspAdmin, props, false)
                  }
                />

                <Route
                  exact
                  path="/customers/:id/settings/add-admin"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedAddMspAdmin, props, false)
                  }
                />

                <Route
                  exact
                  path="/customers/:id/settings/add-whitelist-domain"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedAddWhitelistDomain, props, false)
                  }
                />

                <Route
                  exact
                  path="/customers/:id/settings/add-blacklist-url"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedAddBlacklistUrl, props, false)
                  }
                />

                <Route
                  path="/customers/:id/license-management"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedLicenseManagement, props, false)
                  }
                />
                <Route
                  path="/tenants/:id/license-management"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedLicenseManagement, props, false)
                  }
                />

                <Route
                  path="/customers/:id/deployment"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedDeployment, props, false)
                  }
                />
                <Route
                  path="/tenants/:id/deployment"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedDeployment, props, false)
                  }
                />

                <Route
                  exact
                  path="/tenant-admin/add-organization"
                  render={(props) => this.renderBodyCompoent(ProtectedAddOrganization, props, false)}
                />

                <Route
                  exact
                  path="/customers/:param1/licensemanagement/:param3"
                  render={(props) => this.renderBodyCompoent(ProtectedUserLicenseDetails, props, false)} />

                <Route
                  exact
                  path="/tenants/:param1/:param2/licensemanagement/:param3"
                  render={(props) => this.renderBodyCompoent(ProtectedUserLicenseDetails, props, false)} />

                <Route
                  path="/customers/:id/settings"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedSettings, props, false)
                  }
                />

                <Route
                  exact
                  path="/tenant-admin"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedTenantManagement, props, false)
                  }
                />

                <Route
                  exact
                  path="/soc-integration"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedSocIntegration, props, false)
                  }
                />
                <Route
                  exact
                  path="/advanced-settings"
                  render={(props) =>
                    this.renderBodyCompoent(ProtectedAdvancedSettings, props, false)
                  }
                />

                <Route
                  exact
                  path="*"
                  render={() => {
                    return (
                      this.state.userRole == "TenantAdmin" ?
                        <Redirect to="/tenant-admin" /> :
                        <Redirect to={this.state.isGlobalView ? "/tenants/" : "/customers/" + this.props.selectedCustomer.id + "/incidents"} />
                    )
                  }}
                />

              </Switch>
              {/* </LoaderWrapper> */}
            </BrowserRouter>
          </div>
        </div>
        {/* {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> } */}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedCustomer: state.entities.customers.selected,
  loading: state.entities.customers.loading,
  orgList: state.entities.customers.list,
  userToken: state.entities.user.token,
  userRole: state.entities.user.roles,
  customerFlag: state.entities.customers.createCustomerFlag,
  userMsg: state.entities.customers.userMsg,
  statusCode: state.entities.customers.statusCode,
  domain : state.entities.user.domain
})

const mapDispatchToProps = (dispatch) => ({
  loginIfExisting: () => dispatch(loginIfExisting()),
  loadCustomers: () => dispatch(getCustomerList()),
  setError: (error) => dispatch(setError(error)),
  setNewToken: () => dispatch(setNewToken()),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
