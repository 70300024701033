import React from 'react'


const UpdateFile = (props) => {
    
    const onFileChange = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            let fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000) + ' kB',
                base64: reader.result,
                file: file,
            };
            props.onDone(fileInfo)
        } 
    }

    return (
        <>
            <label htmlFor={ props.inputId } >Update Logo File</label>
            <input
                type="file"
                onChange={e => onFileChange(e)}
                id={ props.inputId } />
        </>
      )

}

export default UpdateFile