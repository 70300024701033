import React, { useEffect, useState } from 'react'
import { getColor, tooltipFormatter } from '../../helpers/utils'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { LegendComponent, ToolboxComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import Loading from '../common/loading'

echarts.use([ToolboxComponent, LegendComponent, BarChart])

const ChartDoubleBar = (props) => {
  const [xaxis, setXaxis] = useState([])
  const [dataArray1, setDataArray1] = useState([])
  const [dataArray2, setDataArray2] = useState([])
  const [loaderStyles, setLoaderStyles] = useState({
    marginTop: '0px',
  })

  useEffect(() => {
    setXaxis(props.xaxis)
    setDataArray1(props.dataArray1)
    setDataArray2(props.dataArray2)
  }, [props])

  const getOption = () => ({
    color: ['#f2d893', '#df1b1b'],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: tooltipFormatter,
      position: function (point, params, dom, rect, size) {
        return [point[0], point[1]]
      },
    },
    yAxis: {
      type: 'value',
      position: 'right',
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 5,
        formatter: function (value, index) {
          return value > 999999
            ? value / 1000000 + 'M'
            : value > 999
            ? value / 1000 + 'K'
            : value
        },
      },
      axisTick: { show: false },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300'),
        },
      },
    },
    xAxis: {
      type: 'category',
      inverse: true,
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300'),
          type: 'solid',
        },
      },
      axisLabel: {
        color: getColor('gray-500'),
      },
      data: xaxis,
    },
    series: [
      {
        name: 'Suspicious',
        type: 'bar',
        data: dataArray1,
        itemStyle: {
          borderRadius: [3, 3, 3, 3],
        },
        barWidth: '13px',
      },
      {
        name: 'Verified Phish',
        type: 'bar',
        data: dataArray2,
        itemStyle: {
          borderRadius: [3, 3, 3, 3],
        },
        barWidth: '13px',
      },
    ],
    grid: { right: '8%', left: '3%', bottom: '8%', top: '8%' },
  })

  return (
    <>
      {props.xaxis !== null &&
      props.dataArray1 !== null &&
      props.dataArray2 !== null ? (
        <ReactEChartsCore
          echarts={echarts}
          option={getOption()}
          style={{ height: '282px', width: '416px' }}
        />
      ) : (
        <div style={{ height: '282px', width: '416px', position: 'relative' }}>
          <Loading loading={true} styles={loaderStyles} />
        </div>
      )}
    </>
  )
}

export default ChartDoubleBar
