import React, { useEffect, useState } from 'react'
import { Card, ProgressBar } from 'react-bootstrap'
import { getColor, tooltipFormatter } from '../../helpers/utils'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { ToolboxComponent, LegendComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import { FiRefreshCcw } from 'react-icons/fi'

echarts.use([ToolboxComponent, LegendComponent, BarChart])

const StackBarChart = (props) => {
  const [showLoader, setShowLoader] = useState(true)
  const [seriesData, setSeriesData] = useState([])

  useEffect(() => {
    if (seriesData.length > 0) {
      seriesData.splice(0, seriesData.length)
    }
    if (props.xaxis && props.legend && props.values) {
      setSeries()
    }
  }, [props])

  const setSeries = () => {
    if (props.values) {
      props.legend.forEach((item, index) => {
        Object.keys(props.values).forEach((key) => {
          if (key === item) {
            seriesData.push({
              name: item,
              type: 'bar',
              tiled: 'total',
              label: {
                show: true,
                color: '#fff',
              },
              emphasis: {
                focus: 'series',
              },
              data: props.values[item],
            })
          }
        })
      })
      setShowLoader(props.loading)
    }
  }

  const getOption = () => ({
    color: [
      getColor('primary'),
      getColor('secondary'),
      getColor('success'),
      getColor('info'),
      getColor('warning'),
      getColor('danger'),
      getColor('light'),
      getColor('dark'),
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('primary') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: tooltipFormatter,
    },
    toolbox: {
      orient: 'vertical',
      itemSize: 22,
      feature: {
        magicType: {
          type: ['stack', 'tiled'],
        },
      },
      right: 0,
    },
    legend: {
      data: props.legend,
      textStyle: {
        color: getColor('600'),
      },
      left: 0,
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('300'),
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('500'),
      },
      splitLine: {
        lineStyle: {
          show: true,
          color: getColor('200'),
        },
      },
    },
    xAxis: {
      type: 'category',
      data: props.xaxis,
      axisLine: {
        lineStyle: {
          show: true,
          color: getColor('300'),
        },
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('500'),
        formatter: (value) => value, //.substring(0, 3),
      },
    },
    series: seriesData,
    grid: {
      right: 15,
      left: 5,
      bottom: 5,
      top: '15%',
      containLabel: true,
    },
  })

  return (
    <Card>
      <Card.Header>
        <div className="d-flex">
          <h6 className="flex-1">{props.heading}</h6>
          <FiRefreshCcw
            style={{ cursor: 'pointer' }}
            onClick={props.onRefresh}
          />
        </div>
      </Card.Header>
      <Card.Body className="position-relative">
        {props.errorNote && props.errorNote.length > 0 ? (
          <p>{props.errorNote}</p>
        ) : (
          <>
            {showLoader ? (
              <ProgressBar animated={true} now={100} />
            ) : (
              <ReactEChartsCore
                echarts={echarts}
                option={getOption()}
                style={{ height: '21.88rem' }}
              />
            )}
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default StackBarChart
